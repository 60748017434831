import { navigateToUrl, start } from "single-spa";
import {
  constructRoutes,
} from "single-spa-layout";
import { customEvents } from "@clearsale/one-lib-events"
import { activateLayoutEngine, constructSingleSpaApplications, createSingleSpaRouter, registerApplications } from "./utils/singleSpa";
import { enableMocking } from "./mocks/browser";
import { HTMLLayoutData } from "single-spa-layout/dist/types/isomorphic/constructRoutes";
import { propsClientPortal } from "./utils/propsClientPortal";

const data: HTMLLayoutData = {
  loaders: {},
  props: {
    ...propsClientPortal
  },
  errors: {}
};
const singleSpaRouter = createSingleSpaRouter();
const routes = constructRoutes(singleSpaRouter, data);
const applications = constructSingleSpaApplications(routes);

function listenerCustomEventChangeRoute(event: CustomEvent) {
  if (event.detail) {
    navigateToUrl(event.detail)
  }
}

function listenerCustomEventTagmanager(event: CustomEvent) {
  const customWindow = window as unknown as DataLayerTagManager
  if (customWindow.dataLayer && event.detail) {
    customWindow.dataLayer.push(event.detail as DataLayerEvent)
  }
}

export interface DataLayerEvent {
  event: string;
  [key: string]: unknown;
}

export type DataLayerTagManager = {
  dataLayer: DataLayerEvent[];
}

enableMocking().then(async () => {
  customEvents.tagManagerEvent.listener(listenerCustomEventTagmanager)
  customEvents.routerEvent.listener(listenerCustomEventChangeRoute)

  registerApplications(applications);
  activateLayoutEngine(routes, applications);
  start({
    urlRerouteOnly: true,
  })
});


