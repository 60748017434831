import { registerApplication, triggerAppChange } from "single-spa";
import { constructApplications, constructLayoutEngine } from "single-spa-layout";

// <application name="@one/one-topbar-app"></application>
export function createSingleSpaRouter() {
  const singleSpaRouter = document.createElement("single-spa-router");
  const routeDefault = document.createElement("route");
  routeDefault.setAttribute("default", "");

  routeDefault.innerHTML = `
      <v-theme-provider>
        <application name="@one/one-topbar-app"></application>
           <application name="@one/navigation-app"></application>
        <route path="/portal">
         <application name="@one/client-portal-app" props="type"></application>
        </route>
        <route path="/shield">
          <application name="@one/shield-app"></application>
        </route>
        <route path="/nexus">
          <application name="@one/nexus-app"></application>
        </route>
        <route path="/app">
          <application name="@one/one-app"></application>
        </route>
        <redirect from="/" to="/app"></redirect>
      </v-theme-provider>
    `;


  singleSpaRouter.appendChild(routeDefault);
  return singleSpaRouter;
}
export function constructSingleSpaApplications(routes) {
  const applications = constructApplications({
    routes,
    loadApp({ name }) {
      return System.import(name);
    },
  });
  return applications;
}

export function activateLayoutEngine(routes, applications) {
  constructLayoutEngine({ routes, applications });
}

export function registerApplications(applications) {
  const topbarAppName = "@one/one-topbar-app";
  const topbarApp = applications.find((app) => app.name === topbarAppName);
  registerApplication(topbarApp)

  triggerAppChange().then(() => {
    const appsWithoutTopbar = applications.filter((app) => app.name !== topbarAppName);
    appsWithoutTopbar.forEach(registerApplication);
  })
}
